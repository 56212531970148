import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ICompleteSignUp {
    cpf: string;
    password: string;
    email: string;
    repeatPassword: string;
    error: string;
    setPasswordInput: (arg0: string) => void;
    setRepeatPasswordInput: (arg0: string) => void;
    setEmail: (arg0: string) => void;
    sendForm: () => void;
}

export default function CompleteSignup({
  cpf,
  setPasswordInput,
  password,
  repeatPassword,
  error,
  setRepeatPasswordInput,
  email,
  setEmail,
  sendForm,
}: ICompleteSignUp) {
  const navigate = useNavigate();
  return (
    <>
      <h5>Última etapa</h5>
      <div className="col-12  d-flex align-items-center justify-content-center mt-4">
        <p className="text-accent">Complete seu cadastro preenchendo os dados abaixo</p>
      </div>
      <div className="input-wrapper">
        <label htmlFor="cpf-input">
          <p>CPF</p>
          <input id="cpf-input" value={cpf} onChange={() => null} readOnly />
        </label>
        <label htmlFor="email-input">
          <p>Email</p>
          <input id="email-input" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </label>
        <label htmlFor="email-input">
          <p>Senha</p>
          <input id="password-input" value={password} onChange={(e) => setPasswordInput(e.target.value)} type="password" />
        </label>
        <label htmlFor="repeat-password-input">
          <p>Repita a senha</p>
          <input id="repeat-password-input" value={repeatPassword} onChange={(e) => setRepeatPasswordInput(e.target.value)} type="password" />
        </label>
        <button type="button" onClick={() => sendForm()}>Próximo</button>
      </div>
      {error !== '' && (<p className="mt-4 situation-text-error text-center">{error}</p>)}
    </>
  );
}
