import { Dispatch } from 'redux';
import { PartnerLoginActionType } from '../reducers/partnerLogin';

export function userLogin(dispatch: Dispatch) {
  dispatch({ type: PartnerLoginActionType.LOGIN });
}

export function userLogout(dispatch: Dispatch) {
  dispatch({ type: PartnerLoginActionType.LOGOFF });
}
