/* eslint-disable default-param-last */
/* eslint-disable no-shadow */
import { AnyAction } from 'redux';

type User = {
    id: number,
    uuid: string,
    email: string | null,
    cpf: string,
    role: string,
    type: string,
    isActive: false
}

export type UserInfo = {
    id: number,
    uuid: string,
    name: string,
    sex: string,
    cpf: string,
    rg: string,
    emitent: string,
    addressStreet: string,
    addressNumber: string,
    addressComplement: null | string,
    addressNeighborhood: string,
    addressCity: string,
    addressState: string,
    addressZipcode: string,
    phone: string,
    isActive: boolean
}

export type UserResponse = {
    user: User,
    info: UserInfo
}

const INITIAL_STORE: UserResponse = {
  user: {
    id: 0,
    uuid: '',
    email: null,
    cpf: '',
    role: '',
    type: '',
    isActive: false,
  },
  info: {
    id: 0,
    uuid: '',
    name: '',
    sex: '',
    cpf: '',
    rg: '',
    emitent: '',
    addressStreet: '',
    addressNumber: '',
    addressComplement: null,
    addressNeighborhood: '',
    addressCity: '',
    addressState: '',
    addressZipcode: '',
    phone: '',
    isActive: false,
  },
};

export enum UserDataEnum {
    SET,
    ERASE
}

export default function Store(state = INITIAL_STORE, action: AnyAction) {
  switch (action.type) {
    case UserDataEnum.SET:
      return { ...state, ...action.payload };
    default: return state;
  }
}
