import { combineReducers } from 'redux';
import showMenuReducer from './reducers/showMenu';
import partnerLoginReducer from './reducers/partnerLogin';
import partnerReducer, { Partner } from './reducers/partnerData';
import userLoginReducer from './reducers/userLogin';
import userDataReducer, { UserResponse } from './reducers/userDatar';

export type storeType = {
  userLoginReducer: { isLoggedIn: boolean };
  partnerLoginReducer: { isLoggedIn: boolean };
  partnerReducer: Partner
  userDataReducer: UserResponse
}

export type menuReducerStoreType = {
  showMenuReducer: {
    showMenu: boolean;
  }
}

export default combineReducers({
  showMenuReducer,
  partnerLoginReducer,
  partnerReducer,
  userLoginReducer,
  userDataReducer,
});
