/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Accredited, professionalFromRaw, Categories,
} from './types';
import api from '../../config/axios';
import Loading from '../../components/Loading';
import AllAccreditedPage from './AllAccredited';
import AccreditedPage from './Accredited';
import { ActualPage, UserResponse } from '../Profile';
import Login from '../Login';
import AllProfessionalsPage from './AllProfessionalsPage';
import ProfessionalPage from './Professional';
import SelectCity from './SelectCity';

export enum BenefitsPageScreen {
  SELECTCITY,
  ALLACCREDITED,
  VIEWACCREDITED,
  ALLPROFESSIONALS,
  PROFESSIONAL
}

export default function BenefitsPage() {
  const { pageURL } = useParams();
  const [actualPage, setActualPage] = useState(BenefitsPageScreen.SELECTCITY);
  const [selectedAccredited, setSelectedAccredited] = useState<Accredited>();
  const [selectedProfessional, setSelectedProfessional] = useState<professionalFromRaw>();
  const [token] = useState({ headers: { Authorization: `bearer ${localStorage.getItem('userToken')}` } });
  const [selectedCity, setSelectedCity] = useState('Imperatriz');
  const [user, setUser] = useState<UserResponse | undefined>();
  const [loading, setLoading] = useState(false);
  const [previousSelectedCategory, setPreviousSelectedCategory] = useState<Categories | null>(null);

  async function tryLogin() {
    try {
      setLoading(true);
      const response = await api.get('/user', token);
      setUser(response.data);
      if (pageURL && pageURL === 'profissionais') {
        setActualPage(BenefitsPageScreen.ALLPROFESSIONALS);
      }
      setLoading(false);
    } catch (err) {
      if (pageURL && pageURL === 'profissionais') {
        setActualPage(BenefitsPageScreen.ALLPROFESSIONALS);
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    tryLogin();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (actualPage === BenefitsPageScreen.SELECTCITY) {
    return <SelectCity setActualPage={(arg0) => setActualPage(arg0)} setSelectCity={(arg0) => setSelectedCity(arg0)} selectedCity={selectedCity} />;
  }

  if (actualPage === BenefitsPageScreen.ALLACCREDITED) {
    return (
      <AllAccreditedPage
        setSelectedAccredited={(accredited) => setSelectedAccredited(accredited)}
        setActualPage={(page) => setActualPage(page)}
        selectedCity={selectedCity}
        user={user}
        setPreviousSelectedCategory={(cat) => setPreviousSelectedCategory(cat)}
        previousSelectedCategory={previousSelectedCategory}
      />
    );
  }

  if (actualPage === BenefitsPageScreen.ALLPROFESSIONALS) {
    return <AllProfessionalsPage setActualPage={(page) => setActualPage(page)} user={user} setSelectedProfessional={(professional) => setSelectedProfessional(professional)} />;
  }

  if (actualPage === BenefitsPageScreen.PROFESSIONAL) {
    return (
      <>
        {
    user ? (
      <ProfessionalPage professional={selectedProfessional!} backToAllAccrediteds={() => setActualPage(BenefitsPageScreen.ALLPROFESSIONALS)} />
    ) : (
      <Login fromBenefitsPage />
      // <ProfessionalPage professional={selectedProfessional!} backToAllAccrediteds={() => setActualPage(BenefitsPageScreen.ALLPROFESSIONALS)} />
    )
    }
      </>
    );
  }
  return selectedAccredited ? (
    <>
      {user ? (
        <AccreditedPage
          selectedAccredited={selectedAccredited!}
          backToAllAccrediteds={() => setActualPage(BenefitsPageScreen.ALLACCREDITED)}
        />
      ) : (
        <Login fromBenefitsPage />
      )}
    </>
  ) : <Loading />;
}
