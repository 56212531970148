/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

export default function Benefits() {
  return (
    <div className="container benefits-container" style={{ paddingTop: 64, paddingBottom: 100 }} id="benefits">
      <h1 className="title-before-center title-internal text-center">Nossos Benefícios</h1>
      <h6 className="text-center text-warning">A ALTERNATIVA PARA QUEM BUSCA SAÚDE DE QUALIDADE COM PREÇO ACESSÍVEL.</h6>
      <div className="row d-flex">
        <div className="col-xs-12 col-md-4 col-lg-3 mb-4">
          <div className="benefits-wrapper">
            <div className="benefits-icon-wrapper">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24 0H0V24H24V0Z" fill="#459CF9" fillOpacity="0.01" />
                <path d="M16 5.5H20L22 10.5H2L4 5.5H8" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4 10.5H20V21.5H4V10.5Z" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16 2.5H8V10.5H16V2.5Z" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 14.5H8V21.5H12V14.5Z" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16 14.5H12V21.5H16V14.5Z" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10.5 6.5H13.5" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18 21.5H6" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 8V5" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

            </div>
            <p>Atendimento Hospitalar</p>
            <text>
              Pronto atendimento 24 horas, atendimento de urgência e emergência,
              internações eletivas e UTI, cirurgias, consultas e exames.
            </text>
          </div>
        </div>

        <div className="col-xs-12 col-md-4 col-lg-3 mb-4">
          <div className="benefits-wrapper">
            <div className="benefits-icon-wrapper">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24 0H0V24H24V0Z" fill="#459CF9" fillOpacity="0.01" />
                <path d="M5 12L10 17L20 7" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <p>Consultas com especialistas</p>
            <text>
              Equipe de saúde altamente qualificada e comprometida.
            </text>
          </div>
        </div>

        <div className="col-xs-12 col-md-4 col-lg-3 mb-4">
          <div className="benefits-wrapper">
            <div className="benefits-icon-wrapper">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24 0H0V24H24V0Z" fill="#459CF9" fillOpacity="0.01" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9 5H15V13.5L22 20.5V22H2V20.5L9 13.5V5Z" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20.25 18.75L18.5 17L16.75 15.25" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20.25 18.75L18.5 17L16.75 15.25" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.25 15.25L5.5 17L3.75 18.75" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.25 15.25L5.5 17L3.75 18.75" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9 2H15" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 13.5V14" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 9V10.5" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5 17.5H19" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <p>Exames</p>
            <text>
              Exames de imagem e laboratoriais. Clínicas e laboratórios certificados,
              com profissionais treinados nas melhores práticas.
            </text>
          </div>
        </div>

        <div className="col-xs-12 col-md-4 col-lg-3 mb-4">
          <div className="benefits-wrapper">
            <div className="benefits-icon-wrapper">
              <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" fill="#459CF9" fillOpacity="0.01" />
                <path fillRule="evenodd" clipRule="evenodd" d="M13.1172 5H14.0026C18.6687 5.68989 19.9341 8.33825 24.0003 8.33825C28.0664 8.33825 29.3314 5.69005 33.9978 5H34.5003C39.747 5 44.0003 9.25329 44.0003 14.5V15.1259C44.0003 18.0287 40.9091 21.3433 40.0003 24.4829C39.035 27.8174 38.3737 30.5425 38.1796 33.3943C37.7248 40.0751 35.447 43 33.0003 43C29.3465 43 26.1017 28.0251 24.0627 28.0251C22.0237 28.0251 17.9776 43 15.0003 43C13.1832 43 10.8293 41.9254 9.94554 33.3943C9.59391 30 9.00122 27.9407 8.00028 24.4829C7.1205 21.4437 4.15199 17.8111 4.00028 14.5511C3.76597 9.51593 7.65782 5.24419 12.693 5.00988C12.8343 5.00331 12.9757 5.00002 13.1172 5Z" fill="none" stroke="#459CF9" strokeWidth="4" />
                <path d="M15.4824 12.4634L32.0005 17.7887" stroke="#459CF9" strokeWidth="4" strokeLinecap="round" />
                <path d="M15.4824 17.7886L32.0005 12.4632" stroke="#459CF9" strokeWidth="4" strokeLinecap="round" />
              </svg>
            </div>
            <p>Odontologia</p>
            <text>
              Tratamento odontológico de qualidade. Corpo clínico altamente qualificado, com a melhor estrutura e tecnologia.
            </text>
          </div>
        </div>

        {/* <div className="col-xs-12 col-md-4 col-lg-3 mb-4">
          <div className="benefits-wrapper">
            <div className="benefits-icon-wrapper">
              <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" fill="white" fillOpacity="0.01" />
                <path d="M4 10C4 8.89543 4.89543 8 6 8H42C43.1046 8 44 8.89543 44 10V38C44 39.1046 43.1046 40 42 40H6C4.89543 40 4 39.1046 4 38V10Z" fill="none" stroke="#459CF9" strokeWidth="4" strokeLinejoin="round" />
                <path d="M4 16H44" stroke="#459CF9" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M27 32H36" stroke="#459CF9" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M44 10V26" stroke="#459CF9" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4 10V26" stroke="#459CF9" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <p>
              Financiamento em despesas médico-hospitalar
            </p>
            <text>
              Facilidade do financiamento de procedimentos hospitalares como cirurgias, internações em leito clínico ou de UTI, no Hospital Santa Mônica,
              {' '}
              <strong>em até 18x através de cartão de crédito.</strong>
            </text>
          </div>
        </div> */}

        <div className="col-xs-12 col-md-4 col-lg-3 mb-4">
          <div className="benefits-wrapper">
            <div className="benefits-icon-wrapper">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24 0H0V24H24V0Z" fill="#459CF9" fillOpacity="0.01" />
                <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5H21L20 21H4L3 7.5Z" stroke="#459CF9" strokeWidth="2" strokeLinejoin="round" />
                <path d="M8 9.5V3H16V9.5" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 17H16" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" />
              </svg>

            </div>
            <p>Clube de Beneficios</p>
            <text>
              Descontos em óticas, farmácias, academias e muito mais.
            </text>
          </div>
        </div>

        <div className="col-xs-12 col-md-4 col-lg-3 mb-4">
          <div className="benefits-wrapper">
            <div className="benefits-icon-wrapper">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.5 22.5H18V20.25C17.9988 19.2558 17.6033 18.3027 16.9003 17.5997C16.1973 16.8967 15.2442 16.5012 14.25 16.5H9.75C8.7558 16.5012 7.80267 16.8967 7.09966 17.5997C6.39666 18.3027 6.00119 19.2558 6 20.25V22.5H4.5V20.25C4.50159 18.8581 5.05522 17.5237 6.03944 16.5394C7.02367 15.5552 8.3581 15.0016 9.75 15H14.25C15.6419 15.0016 16.9763 15.5552 17.9606 16.5394C18.9448 17.5237 19.4984 18.8581 19.5 20.25V22.5ZM3.75 4.5C3.55109 4.5 3.36032 4.57902 3.21967 4.71967C3.07902 4.86032 3 5.05109 3 5.25V12H4.5V5.25C4.5 5.05109 4.42098 4.86032 4.28033 4.71967C4.13968 4.57902 3.94891 4.5 3.75 4.5Z" fill="#459CF9" />
                <path d="M3 1.5V3H6.75V8.25C6.75 9.64239 7.30312 10.9777 8.28769 11.9623C9.27226 12.9469 10.6076 13.5 12 13.5C13.3924 13.5 14.7277 12.9469 15.7123 11.9623C16.6969 10.9777 17.25 9.64239 17.25 8.25V3H21V1.5H3ZM8.25 3H15.75V5.25H8.25V3ZM12 12C11.0054 12 10.0516 11.6049 9.34835 10.9017C8.64509 10.1984 8.25 9.24456 8.25 8.25V6.75H15.75V8.25C15.75 9.24456 15.3549 10.1984 14.6517 10.9017C13.9484 11.6049 12.9946 12 12 12Z" fill="#459CF9" />
              </svg>
            </div>
            <p>Educação</p>
            <text>
              Descontos em universidades regionais.
            </text>
          </div>
        </div>
      </div>
    </div>
  );
}
