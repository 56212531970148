/* eslint-disable max-len */
import React from 'react';

import hospitalSantaMonica from '../../assets/images/santamonica.png';

export default function About() {
  return (
    <div className="container-fluid about-wrapper" id="sobre">
      <div className="container about-happy-doctor-img">
        <div className="row">
          <div className="col-sm-12 col-lg-6 mt-4 d-flex align-items-center flex-column">
            <h1 className="title-before-center title-internal">A redesanta.</h1>
            <p>
              Um sistema de Saúde particular que oferece um pacote múltiplo de BENEFÍCIOS e descontos para saúde e bem
              estar para VOCÊ, sua FAMÍLIA e EMPRESA.
            </p>
            <div className="col-12">
              <div className="hsmonica-partner">
                <h5>Hospital credenciado:</h5>
                <img src={hospitalSantaMonica} alt="Hospital Santa Mônica" />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-6 mt-4 d-flex align-items-center flex-column">
            <h1 className="title-before-center title-internal">Propósito, missão e valores.</h1>
            <p>
              <strong>Nossa missão:</strong>
              {' '}
              Garantir aos nossos clientes mais comodidade de acesso aos serviços de saúde e bem-estar, na rede privada, por um preço acessível.
              {' '}
              <br />
              <br />
              <strong>Nossa visão:</strong>
              {' '}
              Ser referência no segmento de benefícios na assistência à saúde na região tocantina.
              {' '}
              <br />
              <br />
              <strong>Nossos valores:</strong>
              {' '}
              Confiança, excelência, respeito, humanização, responsabilidade, sustentabilidade, ética e inovação.
              {' '}
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
