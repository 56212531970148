import React from 'react';

type ValidatorErrorsProps = {
    errors: string[]
}

export default function ValidatorErrors({ errors }: ValidatorErrorsProps) {
  return (
    <div>
      <h6 className="text-center mb-4">Corrija os seguintes erros</h6>
      {errors.map((item) => (
        <p>
          {item}
          ;
        </p>
      ))}
    </div>
  );
}
