/* eslint-disable no-shadow */
import React from 'react';

export enum IconType {
    PRIMARY = 'primary',
    SECONDARY = 'secondary'
}

interface IconButtonsProps {
    label: string;
    icon: React.SVGProps<SVGSVGElement>;
    type: IconType;
    onClick: () => void;
}

export default function IconButton({
  label, icon, type, onClick,
}: IconButtonsProps) {
  return (
    <button type="button" className={`btn-icon-${type}`} onClick={() => onClick()}>
      {label}
      {icon}
    </button>
  );
}
