/* eslint-disable max-len */
import React from 'react';

import andreaAvatar from '../../assets/images/andrea-avatar.png';
import aureoAvatar from '../../assets/images/auero.png';
import waldirAvatar from '../../assets/images/waldir-avatar.png';
import antonioAvatar from '../../assets/images/antonio-avatar.png';
import medicinaAvatar from '../../assets/images/health-avatar.png';
import alairAvatar from '../../assets/images/alair.png';
import gaiaoAvatar from '../../assets/images/gaiao.png';
import lucianoAvatar from '../../assets/images/luciano.png';
import renataAvatar from '../../assets/images/renata.png';
import johnathanAvatar from '../../assets/images/johnathan.png';
import sergioAvatar from '../../assets/images/sergio.png';
// import adrianoLuzAvatar from '../../assets/images/adrianoluz.png';
import adrianoMedeirosAvatar from '../../assets/images/adrianomedeiros.png';
import faustoFernandes from '../../assets/images/fausto.png';
import michelanyAvatar from '../../assets/images/michelany.png';
import suzaneAvatar from '../../assets/images/suzane.png';
import raquelQueirogaAvatar from '../../assets/images/raquelqueiroga.png';
import laisReisAvatar from '../../assets/images/laisreis.png';
import valerioAvatar from '../../assets/images/valerio.png';
import drienaAvatar from '../../assets/images/driena.png';
import magnoBorbaAvatar from '../../assets/images/magnoborba.png';
import rogerioVerderosiAvatar from '../../assets/images/rogerioverderosi.png';

import TeamComponent from '../../components/TeamComponent';

import lorenaAvatar from '../../assets/images/lorena.png';

export default function Team() {
  return (
    <div className="container-fluid team-container" id="profissionais">
      <div className="container">
        <h1 className="title-before-center title-internal text-center" style={{ marginBottom: 64 }}>Os mais capacitados profissionais!</h1>
        <p className="text-center advisor"><strong className="text-center">Arraste para o lado para ver a lista completa.</strong></p>
        <div className="row d-flex flex-nowrap" id="team-scroll">
          <TeamComponent name="Dr. Waldir Silveira Lage" especialization="Urologia" avatar={waldirAvatar} />
          <TeamComponent name="Dra. Lorena Maia" especialization="Ginecologia e Obstetrícia" avatar={lorenaAvatar} />
          <TeamComponent name="Dr. Áureo Colombi Cangussu" especialization="Otorrinolaringologia" avatar={aureoAvatar} />
          <TeamComponent name="Dra. Andreia Nappo" especialization="Infectologia" avatar={andreaAvatar} />
          <TeamComponent name="Dra. Renata Machado" especialization="Pediatria" avatar={renataAvatar} />
          <TeamComponent name="Dr. Gaião" especialization="Odontologia" avatar={gaiaoAvatar} />
          <TeamComponent name="Dr. Valério de Moraes" especialization="Oftalmologia" avatar={valerioAvatar} />
          <TeamComponent name="Dr. Adriano Medeiros" especialization="Oncologia" avatar={adrianoMedeirosAvatar} />
          <TeamComponent name="Dra. Laís dos Reis" especialization="Psiquiatria" avatar={laisReisAvatar} />
          <TeamComponent name="Dr. Luciano Lage" especialization="Urologia" avatar={lucianoAvatar} />
          <TeamComponent name="Dr. Alair Firmiano" especialization="Urologia" avatar={alairAvatar} />
          <TeamComponent name="Dra. Michelany de Paula" especialization="Ozonioterapia" avatar={michelanyAvatar} />
          <TeamComponent name="Dr. Johnathan Lima" especialization="Nutrição e Medicina Esportiva" avatar={johnathanAvatar} />
          <TeamComponent name="Dr. Sérgio Masciel" especialization="Geriatria" avatar={sergioAvatar} />
          {/* <TeamComponent name="Dr. Adriano Luz" especialization="Cardiologia e Hemodinâmica" avatar={adrianoLuzAvatar} /> */}
          <TeamComponent name="Dra. Suzane Lima" especialization="Harmonização Facial" avatar={suzaneAvatar} />
          <TeamComponent name="Dra. Raquel Queiroga" especialization="Odontologia" avatar={raquelQueirogaAvatar} />
          <TeamComponent name="Dra. Driena Costa" especialization="Reumatologia" avatar={drienaAvatar} />
          <TeamComponent name="Dr. Magno Borba" especialization="Bioquímica" avatar={magnoBorbaAvatar} />
          <TeamComponent name="Dr. Rogério Verderosi" especialization="Clínica Médica" avatar={rogerioVerderosiAvatar} />
          <TeamComponent name="Dr. Fausto R. Fernandes" especialization="Otorrinolaringologia" avatar={faustoFernandes} />
          <TeamComponent name="Dr. Antônio Carlos Monteiro Braconi" especialization="Ortopedia e Traumatologia" avatar={medicinaAvatar} />
          <TeamComponent name="Dr. Pádua" especialization="Oftalmologia" avatar={medicinaAvatar} />
          <TeamComponent name="Dr. Fabio Serra" especialization="Neurologia e Neurocirurgia" avatar={medicinaAvatar} />
          <TeamComponent name="Dr. Alberto Madeira" especialization="Oftalmologia" avatar={medicinaAvatar} />
          <TeamComponent name="Dr. Ubiranei O. Silva" especialization="Cirurgia de Cabeça e Pescoço" avatar={medicinaAvatar} />
          <TeamComponent name="Dr. Filipe Guerra" especialization="Cirurgia de Cabeça e Pescoço" avatar={medicinaAvatar} />
          <TeamComponent name="Dra. Patrícia Pacheco" especialization="Pediatria" avatar={medicinaAvatar} />
          <TeamComponent name="Dra. Karlla Zolinda" especialization="Ginecologia / Obstetrícia" avatar={medicinaAvatar} />
          <TeamComponent name="Dr. Sérgio Masciel" especialization="Geriatria" avatar={medicinaAvatar} />
          <TeamComponent name="Dr. Paulo Erivan" especialization="Dermatologia" avatar={medicinaAvatar} />
          <TeamComponent name="Dr. Olavo Pinheiro" especialization="Cirurgia Torácica" avatar={medicinaAvatar} />
          <TeamComponent name="Dr. Marcos André" especialization="Pediatria" avatar={medicinaAvatar} />
          <TeamComponent name="Dr. Fábio Chaves" especialization="Oftalmologia" avatar={medicinaAvatar} />
          <TeamComponent name="Dr. Edson Martins" especialization="Gastroenterologia" avatar={medicinaAvatar} />
          <TeamComponent name="Dr. Diogo Sales" especialization="Ortopedia" avatar={medicinaAvatar} />
          <TeamComponent name="Dr. Afranio de Moraes" especialization="Oftalmologia" avatar={medicinaAvatar} />
          <TeamComponent name="Dra. Stephannie Freitas" especialization="Ozonioterapia" avatar={medicinaAvatar} />
          <TeamComponent name="Dr. Mayana Ferreira" especialization="Fisioterapia" avatar={medicinaAvatar} />
          <TeamComponent name="Dra. Tamara" especialization="Psicologia" avatar={medicinaAvatar} />
        </div>
      </div>
    </div>
  );
}
