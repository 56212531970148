/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

enum SelectedProduct {
  EMPRESARIAL,
  INDIVIDUAL,
}

export default function Product() {
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState(SelectedProduct.INDIVIDUAL);
  return (
    <div className="container-fluid product" id="produtos">
      <div className="container">
        <div className="row h-100">
          <div className="col-xs-12 col-lg-6 h-100">
            <h1 className="title-before title-internal">Soluções</h1>
            <div className="col-12">
              <div className="product-type-wrapper">
                <span className={selectedProduct === SelectedProduct.INDIVIDUAL ? 'checked' : ''} onClick={() => setSelectedProduct(SelectedProduct.INDIVIDUAL)} style={{ cursor: 'pointer' }}>
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.9056 21.0944C22.376 18.3851 23.8037 12.5648 21.0944 8.09437C18.385 3.62398 12.5647 2.19634 8.09436 4.90565C3.62397 7.61496 2.19633 13.4353 4.90564 17.9057C7.61495 22.376 13.4353 23.8037 17.9056 21.0944Z" fill="#006CB6" />
                    <path d="M13.0077 22.0165C17.9947 22.0165 22.0374 17.9738 22.0374 12.9868C22.0374 7.99983 17.9947 3.95709 13.0077 3.95709C8.02076 3.95709 3.97803 7.99983 3.97803 12.9868C3.97803 17.9738 8.02076 22.0165 13.0077 22.0165Z" fill="url(#paint0_linear_383_150)" />
                    <path d="M19.0381 19.0456C22.3732 15.7105 22.3732 10.3032 19.0381 6.96809C15.703 3.63299 10.2957 3.63299 6.96061 6.96809C3.62551 10.3032 3.62551 15.7105 6.96061 19.0456C10.2957 22.3807 15.703 22.3807 19.0381 19.0456Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.2889 7.32964C10.724 7.38404 10.996 7.81921 10.8872 8.19998C10.8328 8.63514 10.3976 8.90712 10.0169 8.79833C9.58171 8.74394 9.30973 8.30877 9.41852 7.928C9.47292 7.54723 9.85369 7.22085 10.2889 7.32964Z" fill="#1EBCBE" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.1804 12.0621C10.398 11.8989 10.67 11.7357 10.942 11.6813C11.3771 11.5181 11.8667 11.4637 12.3563 11.5725C12.4651 11.5725 12.5195 11.6813 12.5195 11.7901C12.5195 12.0077 12.5195 12.1709 12.4651 12.3885C12.4651 12.4972 12.3563 12.5516 12.1931 12.5516C11.8667 12.4972 11.5403 12.4972 11.214 12.606C10.7788 12.7692 10.4524 13.0412 10.2348 13.422C10.2348 13.4764 10.1804 13.5308 10.126 13.5308C10.126 13.5308 10.126 13.5308 10.0716 13.5308C10.0172 13.5308 9.96285 13.5308 9.90846 13.5308C9.74527 13.3676 9.58208 13.15 9.47329 12.9324C9.4189 12.8236 9.3645 12.7148 9.3101 12.606C8.76615 11.1374 9.52769 9.55987 10.9964 9.01591C12.4651 8.47196 14.0425 9.2335 14.5865 10.7022C14.6409 10.9198 14.6953 11.083 14.7497 11.3005C14.7497 11.3549 14.7497 11.4637 14.6409 11.5181C14.5865 11.5725 14.4777 11.5725 14.4233 11.5181C14.2601 11.4093 14.0969 11.3549 13.8794 11.3005C13.825 11.3005 13.7706 11.2461 13.7162 11.1374C13.7162 11.083 13.6618 11.0286 13.6618 10.9742C13.3354 9.99504 12.2475 9.50548 11.2684 9.83185C10.398 10.1038 9.85406 11.1374 10.1804 12.0621Z" fill="#1EBCBE" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.6066 12.4429C13.6066 12.3885 13.661 12.3341 13.7154 12.3341C13.7154 12.3341 13.7154 12.3341 13.7698 12.3341C13.8242 12.3341 13.8786 12.3341 13.933 12.3341C14.0962 12.4973 14.2594 12.7148 14.3682 12.9324C14.4226 13.0412 14.477 13.15 14.5314 13.2588C15.0753 14.7275 14.3138 16.305 12.8451 16.8489C11.3764 17.3929 9.79894 16.6313 9.25498 15.1626C9.20059 14.9451 9.14619 14.7819 9.0918 14.5643C9.0918 14.5099 9.0918 14.4011 9.20059 14.3467C9.25498 14.2923 9.36378 14.2923 9.41817 14.3467C9.58136 14.4555 9.74455 14.5099 9.96213 14.5643C10.0165 14.5643 10.0709 14.6187 10.1253 14.7275C10.1253 14.7819 10.1797 14.8363 10.1797 14.8907C10.5061 15.8698 11.594 16.3594 12.5731 16.033C13.4979 15.7066 14.0418 14.6731 13.7154 13.7484C13.4979 13.9115 13.2259 14.0747 12.9539 14.1291C12.5187 14.2923 12.0292 14.3467 11.5396 14.2379C11.4308 14.2379 11.3764 14.1291 11.3764 14.0203C11.3764 13.8028 11.3764 13.6396 11.4308 13.422C11.4852 13.3132 11.5396 13.2588 11.7028 13.2588C12.0292 13.3132 12.3555 13.3132 12.6819 13.2044C13.0083 13.15 13.3891 12.8236 13.6066 12.4429Z" fill="#006CB6" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.9122 15.1626C15.6738 15.0539 16.3265 14.4555 16.4897 13.6396C16.6529 12.6604 16.0001 11.6813 15.021 11.4637C15.0754 11.3549 15.1298 11.2461 15.0754 11.083C15.0754 10.8654 15.021 10.7022 14.9666 10.4846C15.021 10.4846 15.0754 10.4846 15.1298 10.4846C16.6529 10.7566 17.6864 12.2253 17.4144 13.7484C17.3056 14.4555 16.8705 15.1082 16.2721 15.5434C15.7282 15.9242 15.1298 16.0874 14.4771 16.0874C14.6946 15.8154 14.8578 15.489 14.9122 15.1626Z" fill="#006CB6" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M15.6192 12.606C15.9456 12.6604 16.2176 12.9868 16.1088 13.3132C16.0544 13.6396 15.728 13.9115 15.4016 13.8027C15.0752 13.7483 14.8033 13.422 14.9121 13.0956C14.9665 12.8236 15.2928 12.5516 15.6192 12.606Z" fill="#006CB6" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.2801 17.1753C13.7152 17.2297 13.9872 17.6648 13.8784 18.0456C13.824 18.4808 13.3889 18.7528 13.0081 18.644C12.5729 18.5896 12.3009 18.1544 12.4097 17.7736C12.4641 17.3929 12.8993 17.1209 13.2801 17.1753Z" fill="#006CB6" />
                    <defs>
                      <linearGradient id="paint0_linear_383_150" x1="8.31278" y1="8.24957" x2="18.3259" y2="18.3525" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F27172" />
                        <stop offset="1" stopColor="#1EBCBE" />
                      </linearGradient>
                    </defs>
                  </svg>
                  Familiar
                </span>
              </div>
              <div className="product-description-wrapper">
                {selectedProduct === SelectedProduct.EMPRESARIAL ? (
                  <h5>Diferenciais da redesanta para sua empresa e seus colaboradores:</h5>
                ) : (
                  <h5>Diferenciais da redesanta para você e sua família:</h5>
                )}
                <div className="product-description-list">
                  <div className="product-description">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M24 0H0V24H24V0Z" fill="#459CF9" fillOpacity="0.01" />
                      <path d="M16 5.5H20L22 10.5H2L4 5.5H8" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M4 10.5H20V21.5H4V10.5Z" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M16 2.5H8V10.5H16V2.5Z" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M12 14.5H8V21.5H12V14.5Z" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M16 14.5H12V21.5H16V14.5Z" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M10.5 6.5H13.5" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M18 21.5H6" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M12 8V5" stroke="#459CF9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <p>
                      Descontos atrativos em assistência hospitalar, incluindo pronto-socorro 24 horas;
                    </p>
                  </div>
                  <div className="product-description">
                    <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#icon-bfd092ed4818cf0)">
                        <path d="M42 20V39C42 40.6569 40.6569 42 39 42H9C7.34315 42 6 40.6569 6 39V9C6 7.34315 7.34315 6 9 6H30" stroke="#459CF9" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16 20L26 28L41 7" stroke="#459CF9" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                      <defs><clipPath id="icon-bfd092ed4818cf0"><rect width="48" height="48" fill="#459CF9" /></clipPath></defs>
                    </svg>
                    <p>
                      Atendimento diferenciado;
                    </p>
                  </div>
                  <div className="product-description">
                    <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="48" height="48" fill="white" fillOpacity="0.01" />
                      <path d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" fill="none" stroke="#459CF9" strokeWidth="4" strokeLinejoin="round" />
                      <path d="M31 18V19" stroke="#459CF9" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M17 18V19" stroke="#459CF9" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M31 31C31 31 29 35 24 35C19 35 17 31 17 31" stroke="#459CF9" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <p>
                      Melhor custo beneficio.
                    </p>
                  </div>
                  <div className="product-description">
                    <svg stroke="currentColor" fill="#459CF9" strokeWidth="0" viewBox="0 0 512 512" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z" /></svg>
                    <p>
                      Clube de benefícios.
                    </p>
                  </div>
                </div>
              </div>
              <a href="#beneficios">Veja todos benefícios.</a>
            </div>

          </div>
          {selectedProduct === SelectedProduct.EMPRESARIAL && (
          <div className="col-xs-12 col-lg-6 product-price-card-container">
            <div className="product-price-card" style={{ backgroundColor: '#D9F2FE', padding: 84 }}>
              <div className="card-mobile">
                <div className="card-primary">
                  <h1>EMPRESARIAL</h1>
                  <h4>
                    R$29,90
                    {' '}
                    <strong>/ Mês </strong>
                  </h4>
                  <div className="item-container">
                    <p className="fw-bold text-center">Descontos em:</p>
                    <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>Atendimento Hospitalar.</p>
                    </div>
                    <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>Consultas com os melhores especialistas da região tocantina.</p>
                    </div>
                    <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>Exames de imagem e laboratoriais.</p>
                    </div>
                    <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>Fisioterapia.</p>
                    </div>
                    <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>Odontologia.</p>
                    </div>
                    <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>Descontos em óticas, farmácias, academias e universidade.</p>
                    </div>
                    <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>Adicional de dependentes por R$6,90.</p>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-2">
                  <button type="button" className="select-product-cta" onClick={() => navigate('/assine')}>
                    Adquira já
                  </button>
                </div>
              </div>
            </div>
          </div>
          )}
          {selectedProduct === SelectedProduct.INDIVIDUAL && (
          <div className="col-xs-12 col-lg-6 product-price-card-container">
            <div className="product-price-card" style={{ backgroundColor: '#D9F2FE', padding: 84 }}>
              <div className="card-mobile">
                <div className="card-primary">
                  <h1>FAMILIAR</h1>
                  <h4>
                    R$39,90
                    {' '}
                    <strong>/ Mês </strong>
                  </h4>
                  <div className="item-container">
                    <p className="fw-bold text-center">Descontos em:</p>
                    <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>Pronto Socorro 24h.</p>
                    </div>
                    <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>Internação.</p>
                    </div>
                    <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>Cirurgias.</p>
                    </div>
                    <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>Consultas com os melhores especialistas da região.</p>
                    </div>
                    <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>Exames de imagem.</p>
                    </div>
                    <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>Exames laboratoriais.</p>
                    </div>
                    <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>Odontologia.</p>
                    </div>
                    <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>Descontos em farmácia, ótica, academia, posto de combustível, cinema e MUITO MAIS!!!.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-2">
                <button type="button" className="select-product-cta" onClick={() => navigate('/assine')}>
                  Adquira já
                </button>
              </div>
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
}
