export default function showCompactName(name: string) {
  const splittedName = name.split(' ');
  if (splittedName.length <= 2) {
    return name;
  }
  let response = '';
  splittedName.forEach((item, i) => {
    if (i === 0) {
      response = response.concat(item);
    } else if (i === splittedName.length - 1) {
      response = response.concat(item);
    } else {
      response = response.concat(` ${item[0]}. `);
    }
  });
  return response;
}
