/* eslint-disable no-shadow */
/* eslint-disable default-param-last */
import { AnyAction } from 'redux';

const INITIAL_STORE = {
  showMenu: false,
};

export enum menuActionType {
    SHOW = 'SHOW',
    HIDE = 'HIDE',
}

export default function Store(state = INITIAL_STORE, action: AnyAction) {
  switch (action.type) {
    case menuActionType.SHOW:
      return { ...state, showMenu: true };
    case menuActionType.HIDE:
      return { ...state, showMenu: false };
    default: return state;
  }
}
