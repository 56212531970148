export default function capitalizeFirstLetter(data: string) {
  if (data.length > 0) {
    const separateWord = data.toLowerCase().split(' ');
    for (let i = 0; i < separateWord.length; i += 1) {
      separateWord[i] = separateWord[i].charAt(0).toUpperCase()
         + separateWord[i].substring(1);
    }
    return separateWord.join(' ');
  }
  return '';
}

export function formatEnterpriseSize(data: string) {
  if (data === 'MICRO EMPRESA') {
    return 'ME';
  }
  if (data === 'EMPRESA DE PEQUENO PORTE') {
    return 'EPP';
  }
  return 'OUTRO';
}

export function formatDate(data: string) {
  if (data.length > 0) {
    const splitted = data.split('/');
    return `${splitted[0]}-${splitted[1]}-${splitted[2]}`;
  }
  return '';
}
