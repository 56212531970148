/* eslint-disable react/require-default-props */
import React, { useState } from 'react';

interface IDefaultInputProps {
    id: string
    label: string
    error?: string
    onChange: (arg0: string) => void
    loading: boolean
    placeholder?: string,
    elements: string[],
    value: string
}

export default function DefaultOption({
  label, error, onChange, loading, id, placeholder, elements, value,
}: IDefaultInputProps) {
  function setElement(item: string) {
    onChange(item);
    const hiddenElement: any = document.querySelectorAll('.hidden-content');

    for (let x = 0; x < hiddenElement.length; x += 1) {
      hiddenElement[x].style.display = 'none';
    }

    setTimeout(() => {
      for (let x = 0; x < hiddenElement.length; x += 1) {
        hiddenElement[x].style.display = 'flex';
      }
    }, 100);
  }
  return (
    <div className={`option-wrapper-2 ${error ? 'input-error' : ''}`}>
      <label htmlFor={id} className="d-flex flex-column">
        {label}
        <select onChange={(el) => setElement(el.target.value)} id={id} style={{ }} value={value}>
          {elements.map((item) => (
            <option
              className="hidden-option"
              key={item}
            >
              {item}
            </option>
          ))}
        </select>
      </label>
      <span className="material-icons-round">expand_more</span>
      {error && (
      <span className="text-danger text-input-error d-flex align-items-center justify-content-center">
        <span className="material-icons-round">error</span>
        {error}
      </span>
      )}
    </div>
  );
}
