import cep from 'cep-promise';

/* eslint-disable semi */
export type AddressProps = {
    street: string,
    neighborhood: string,
    city: string,
    state: string;
}

export interface ICepFinder {
    findCep(): Promise<AddressProps | null>;
    cep: string;
}

export default class CEPFinder implements ICepFinder {
  cep: string = '';

  constructor(cepCtx: string) {
    this.cep = cepCtx;
  }

  public async findCep(): Promise<AddressProps | null> {
    try {
      const result = await cep(this.cep, { timeout: 5000 });
      return {
        street: result.street,
        neighborhood: result.neighborhood,
        city: result.city,
        state: result.state,
      };
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}
