import axios from 'axios';

const api = axios.create({
  // baseURL: 'http://localhost:3333',
  // baseURL: 'https://b680-189-106-159-52.ngrok-free.app/',
  baseURL: 'https://api.redesanta.com',
  // baseURL: 'http://192.168.10.2:3333',
  // baseURL: 'http://127.0.0.1:3333',
  // headers: {
  //   'ngrok-skip-browser-warning': true,
  // },
});
export default api;
