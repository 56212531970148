import { mask, unMask } from 'remask';

export function maskCNPJ(data) {
  const unmasked = unMask(data);
  const masked = mask(unmasked, '99.999.999/9999-99');
  return masked;
}

export function unmaskData(data) {
  const unmasked = unMask(data);
  return unmasked;
}

export function maskCPF(data) {
  const unmasked = unMask(data);
  const masked = mask(unmasked, '999.999.999-99');
  return masked;
}

export function maskCardExpiration(data) {
  const unmasked = unMask(data);
  const masked = mask(unmasked, '99/9999');
  return masked;
}

export function maskHour(data) {
  if (Number(data) > 24) {
    return '24';
  }
  if (Number(data) < 0) {
    return '00';
  }
  return data;
}

export function maskMinuteAndSeconds(data) {
  if (Number(data) > 59) {
    return '59';
  }
  if (Number(data) < 0) {
    return '00';
  }
  return data;
}

export function maskCPForCNPJ(data) {
  const unmasked = unMask(data);
  const masked = mask(unmasked, ['999.999.999-99', '99.999.999/9999-99.']);
  return masked;
}

export function maskPhone(data) {
  const unmasked = unMask(data);
  const masked = mask(unmasked, ['(99) 9999-9999', '(99) 99999-9999']);
  return masked;
}

export function maskNumber(data) {
  const unmasked = unMask(data);
  const masked = mask(unmasked, '99999999999999999999999999999999999999999');
  return masked;
}

export function maskDate(data) {
  const unmasked = unMask(data);
  const masked = mask(unmasked, '99-99-9999');
  return masked;
}

export function maskMoney(data) {
  const unmasked = unMask(data);
  const masked = mask(unmasked, ['999999999999999']);
  return masked;
}

export function normalizeDate(date) {
  const firstSplit = date.split('-');
  return `${firstSplit[2]}-${firstSplit[1]}-${firstSplit[0]}`;
}

export function maskCep(data) {
  const unmasked = unMask(data);
  const masked = mask(unmasked, '99.999-999');
  return masked;
}

export function maskRegistration(data) {
  const unmasked = unMask(data);
  const masked = mask(unmasked, ['99 99 99-9999-99-99']);
  return masked;
}
