import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import { createStore } from 'redux';
import App from './routes';
import reportWebVitals from './reportWebVitals';
import './assets/styles/index.css';

import reduxStore from './store';

const tagManagerArgs = {
  gtmId: 'GTM-KWM94TD',
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={createStore(reduxStore)}>
      <App />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
