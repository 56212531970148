import React from 'react';

type ModalResponseProps = {
    id: string
    loading: boolean
    element: React.ReactNode
}

export default function ModalResponse({ id, loading, element }: ModalResponseProps) {
  return (
    <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {
                loading ? 'Processando Pagamento' : ''
            }
            </h5>
            {loading && (
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            )}
          </div>
          <div className="modal-body">
            {element}
          </div>
          <div className="modal-footer">
            {!loading && (
            <button type="button" className="btn btn-primary">Fechar</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
