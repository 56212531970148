/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import Landing from './Layout/Landing';
import BenefitsPage from './Layout/BenefitsPage';
import Profile from './Layout/Profile';
import Login from './Layout/Login';
import Lead from './Layout/Lead';
import LeadSupMateus from './Layout/Lead/Partners/SupermercadoMateus';

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Landing />} />
        <Route path="/beneficios" element={<BenefitsPage />} />
        <Route path="/beneficios/:pageURL" element={<BenefitsPage />} />
        <Route path="/perfil" element={<Profile />} />
        <Route path="/login" element={<Login />} />
        <Route path="/assine" element={<Lead />} />
        <Route path="/assine/supermercado-mateus" element={<LeadSupMateus />} />
      </Routes>
    </BrowserRouter>
  );
}
