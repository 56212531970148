import { Dispatch } from 'redux';
import { menuActionType } from '../reducers/showMenu';

export function showMenuAction(dispatch: Dispatch) {
  dispatch({ type: menuActionType.SHOW });
}

export function showHideMenuAction(dispatch: Dispatch) {
  dispatch({ type: menuActionType.HIDE });
}
