import React from 'react';

export default function Loading() {
  return (
    <div className="container flex-column d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
      <p>Carregando, aguarde...</p>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
