/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import * as htmlToImage from 'html-to-image';
import QRCode from 'qrcode';
import download from 'downloadjs';
import { DateTime } from 'luxon';
import { ActualPage, Payment, UserResponse } from '.';
import { maskCPF } from '../../helpers/maskInputs.js';
import Card from '../../components/Card/Card';
import api from '../../config/axios';

type UserInfoProps = {
    user: UserResponse
    setActualPage: (arg0: ActualPage) => void;
    logOut: () => void;
    payment: Payment[]
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

enum PageEnum {
  INFO,
  PAYMENT
}

export default function UserInfo({
  user, setActualPage, logOut, payment, setLoading,
}: UserInfoProps) {
  const [isCardFront, setIsCardFront] = useState(false);

  function getFirstName(fullName: string) {
    return fullName.split(' ')[0];
  }

  function resolveStatus(status: string, date: string) {
    if (status === 'OTHER') {
      return <p className="label-authorized">AUTORIZADO</p>;
    }
    if (status === 'PAID') {
      return <p className="label-paid">PAGO</p>;
    }
    if (status === 'UNPAID') {
      const now = DateTime.local({ zone: 'America/Sao_Paulo' });
      const dueDate = DateTime.fromFormat(`${date} 23:59:59`, 'dd-MM-yyyy hh:mm:ss');
      if (now > dueDate) {
        return <p className="label-due">VENCIDO</p>;
      }
      return <p className="label-ontime">NO PRAZO</p>;
    }
    return <p className="label-reversed">ESTORNADO</p>;
  }

  async function getCharge(installmentId: number) {
    try {
      setLoading(true);
      const response = await api.get(`user/payment-pdf/${installmentId}`, { headers: { Authorization: `bearer ${localStorage.getItem('userToken')}` } });
      window.open(response.data, '_blank');
      setLoading(false);
    } catch (err) {
      alert('Não existe um documento gerado para essa parcela.');
      setLoading(false);
    }
  }

  return (
    <>
      <h4 className="text-center">
        Bem-vindo de volta,
        {' '}
        <span>{`${getFirstName(user.info.name)}.`}</span>
      </h4>
      <div className="row mb-4 card-user">
        <Card
          id="card-user-a"
          registration={user.info.registration}
          name={user.info.name}
          cpf={user.info.cpf}
          expiration={user.card.validade}
          birth={user.info.birthday}
          modality={user.card.modalidade}
          category={user.card.categoria}
          front={isCardFront}
          setCardSide={() => setIsCardFront((prev) => !prev)}
        />
      </div>
      <div className="col-12 d-flex align-items-center justify-content-center mb-4">
        <button type="button" onClick={() => logOut()} className="logout-btn">Sair da conta</button>
      </div>
      <div className="col-12 user-data-card">
        <h5 className="mb-4">Dados pessoais</h5>
        <div className="item-wrapper br-top">
          <div className="title">
            <p>Nome</p>
          </div>
          <div className="description">
            <p>{user.info.name}</p>
          </div>
        </div>
        <div className="item-wrapper">
          <div className="title">
            <p>CPF</p>
          </div>
          <div className="description">
            <p>{maskCPF(user.info.cpf)}</p>
          </div>
        </div>
        <div className="item-wrapper">
          <div className="title">
            <p>RG</p>
          </div>
          <div className="description">
            <p>{user.info.rg}</p>
          </div>
        </div>
        <div className="item-wrapper">
          <div className="title">
            <p>Logradouro</p>
          </div>
          <div className="description">
            <p>{user.info.addressStreet}</p>
          </div>
        </div>
        <div className="item-wrapper">
          <div className="title">
            <p>Número</p>
          </div>
          <div className="description">
            <p>{user.info.addressNumber}</p>
          </div>
        </div>
        <div className="item-wrapper">
          <div className="title">
            <p>Bairro</p>
          </div>
          <div className="description">
            <p>{user.info.addressNeighborhood}</p>
          </div>
        </div>
        <div className="item-wrapper">
          <div className="title">
            <p>Complemento</p>
          </div>
          <div className="description">
            <p>{user.info.addressComplement ? user.info.addressComplement : 'N/A'}</p>
          </div>
        </div>
        <div className="item-wrapper">
          <div className="title">
            <p>Cidade</p>
          </div>
          <div className="description">
            <p>{user.info.addressCity}</p>
          </div>
        </div>
        <div className="item-wrapper">
          <div className="edit">
            <button type="button" onClick={() => setActualPage(ActualPage.UPDATEEMAIL)}>alterar</button>
          </div>
          <div className="title">
            <p>Email</p>
          </div>
          <div className="description">
            <p>{user.user.email ? user.user.email : 'N/A'}</p>
          </div>
        </div>
        <div className="item-wrapper br-bottom">
          <div className="edit">
            <button type="button" onClick={() => setActualPage(ActualPage.UPDATEPASSWORD)}>alterar</button>
          </div>
          <div className="title">
            <p>Senha</p>
          </div>
          <div className="description">
            <p>Protegido</p>
          </div>
        </div>
      </div>
      <div className="col-12 user-data-card">
        {payment.length > 0 && (
          <h5 className="mb-4 mt-4">Financeiro</h5>
        )}
        {
          payment.map((item, i) => (
            <div className="item-wrapper br-top">
              <div className="title">
                <p>
                  Vencimento:
                  {' '}
                  {item.dueDate}
                </p>
              </div>
              <div className="description d-flex justify-content-between">
                <p>{resolveStatus(item.status, item.dueDate)}</p>
                {item.status !== 'PAID' && (item.nonCardTaxes === 'BOLETO' || item.nonCardTaxes === 'PIX') && (
                  <button type="button" className="download-pdf-link fw-bold" onClick={() => getCharge(item.id)}>
                    2˚ Via.
                  </button>
                )}
                {/* <button className="btn btn-primary" type="button">
                  Segunda via
                </button> */}
              </div>
            </div>
          ))
        }
      </div>
    </>
  );
}
