/* eslint-disable max-len */
import React, { useEffect } from 'react';

export default function Steps() {
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 200) {
        const stepsArea = document.getElementById('steps');
        stepsArea?.classList.replace('steps-container-invisible', 'steps-container');
      }
    });
  });
  return (
    <div className="container-fluid steps-container-invisible" id="steps">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="title-before-center title-internal text-center">Veja como é fácil contratar</h1>
          </div>
          <div className="row steps h-100 d-flex justify-content-between">
            <div className="col-xs-12 col-lg-4">
              <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" fill="white" fillOpacity="0.01" />
                <path d="M44 24C44 35.0457 35.0457 44 24 44C18.0265 44 4 44 4 44C4 44 4 29.0722 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24Z" fill="none" stroke="#1DA1F2" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14 18L32 18" stroke="#1DA1F2" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14 26H32" stroke="#1DA1F2" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14 34H24" stroke="#1DA1F2" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <p>1. Você faz a adesão</p>
              <p>Fale com um representante!</p>
            </div>
            <div className="col-xs-12 col-lg-4">
              <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" fill="white" fillOpacity="0.01" />
                <path d="M19 20C22.866 20 26 16.866 26 13C26 9.13401 22.866 6 19 6C15.134 6 12 9.13401 12 13C12 16.866 15.134 20 19 20Z" fill="none" stroke="#1DA1F2" strokeWidth="4" strokeLinejoin="round" />
                <path d="M37 40V30" stroke="#1DA1F2" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M33 36L37 40L41 36" stroke="#1DA1F2" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M27 28H18.8C14.3196 28 12.0794 28 10.3681 28.8719C8.86278 29.6389 7.63893 30.8628 6.87195 32.3681C6 34.0794 6 36.3196 6 40.8V42H27" stroke="#1DA1F2" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <p>2. Realizamos o cadastro</p>
              <p>
                nosso representante coletará os dados e realizará seu cadastro!
              </p>
            </div>
            <div className="col-xs-12 col-lg-4">
              <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" fill="white" fillOpacity="0.01" />
                <path d="M20.906 6.06267L22.3359 5.1094C23.3436 4.4376 24.6564 4.4376 25.6641 5.1094L27.094 6.06267C32.658 9.77199 36 16.0166 36 22.7037V33H12V22.7037C12 16.0166 15.342 9.77199 20.906 6.06267Z" stroke="#1DA1F2" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <circle cx="24" cy="20" r="6" fill="none" stroke="#1DA1F2" strokeWidth="4" />
                <path d="M12 22L6 28.2174V33H42V28.2174L36 22" stroke="#1DA1F2" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M24 38V44" stroke="#1DA1F2" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16 40V42" stroke="#1DA1F2" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M32 40V42" stroke="#1DA1F2" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <p>3. E pronto!</p>
              <p>
                Todos os titulares e dependentes possuem acesso
                aos descontos e benefícios.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
