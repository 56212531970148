import React from 'react';

interface TeamComponentProps {
    name: string;
    especialization: string;
    avatar: string;
}

export default function TeamComponent({ name, especialization, avatar }: TeamComponentProps) {
  return (
    <div className="col-xs-12 col-md-6 col-xl-3 mb-4 d-flex justify-content-center d-flex justify-content-center">
      <div className="team-wrapper">
        <div className="team-avatar-wrapper">
          <img src={avatar} alt="avatar" />
        </div>
        <h1>{name}</h1>
        <div className="divider" />
        <p>{especialization}</p>
      </div>
    </div>
  );
}
