/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import About from './About';
import Benefits from './Benefits';
import FAQ from './FAQ';
import Footer from './Footer';
import HeaderCompact from './HeaderCompact';
import MenuArea from './MenuArea';
import Partners from './Partners';
import Team from './Team';
import { menuReducerStoreType } from '../../store';
import { showHideMenuAction, showMenuAction } from '../../store/actions/menuAction';
import WorkWithUs from './WorkWithUs';
import HeaderV2 from './HeaderV2';
import Herov3 from './Herov3';
import Steps from './Steps';
import Product from './product';
import Banner from './Banner';
import Carousel from '../../components/Carousel';
import Herov4 from './Herov4';

function App() {
  const dispatch = useDispatch();
  const { showMenu } = useSelector(({ showMenuReducer }: menuReducerStoreType) => showMenuReducer);
  const [visibleMenu, setVisibleMenu] = useState(showMenu);
  const [showWorkWithUs, setShowWorkWithUs] = useState(false);

  useEffect(() => {

  }, [showMenu]);

  useEffect(() => {
    if (showMenu) {
      showMenuAction(dispatch);
    } else {
      showHideMenuAction(dispatch);
    }
  }, [visibleMenu]);

  return (
    <div className="p-relative overflow-hidden">
      <HeaderV2 />
      <HeaderCompact setMenuVisibility={setVisibleMenu} visibilityMenu={visibleMenu} />
      {visibleMenu && (<MenuArea setMenuVisibility={setVisibleMenu} visibilityMenu={visibleMenu} />)}
      {/* <Herov4 /> */}
      {/* <Carousel elements={[<Herov3 />, <Banner />]} /> */}
      <Herov3 />
      {/* <Herov3 />
      <Banner /> */}
      <Product />
      <Steps />
      <Partners />
      <Benefits />
      <About />
      <Team />
      <FAQ showWorkWithUs={setShowWorkWithUs} />
      <Footer showWorkWithUs={setShowWorkWithUs} />
      <WorkWithUs show={showWorkWithUs} setShowWorkWithUs={setShowWorkWithUs} />
    </div>
  );
}

export default App;
