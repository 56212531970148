/* eslint-disable max-len */
import React from 'react';
import { Product } from '.';

interface ISelectProductProps {
    selectProduct: (arg0: Product) => void;
    product: Product[]
}

export default function SelectProduct({ selectProduct, product }: ISelectProductProps) {
  const formatedProducts = product.map((item) => ({
    ...item,
    benefits: item.benefits.split(';'),
  }));
  return (
    <div className="container">
      <div className="select-product-header">
        <h3>Escolha um produto</h3>
      </div>
      <p className="text-center">Temos 3 opções. Deslize para ver todos os produtos abaixo.</p>
      <div className="row">
        {formatedProducts.sort((a, b) => a.id - b.id).map((item) => (
          <div className="col-xs-12 col-lg-4 product-price-card-container d-flex align-items-center justify-content-center flex-column" key={item.id}>
            <div className="product-price-card" style={{ backgroundColor: '#D9F2FE', padding: 84 }}>
              <div className="card-mobile">
                <div className="card-primary">
                  <h1>{item.name}</h1>
                  <h4>
                    R$
                    {item.pricePerHolder.toFixed(2)}
                    {' '}
                    <strong>/ Mês </strong>
                  </h4>
                  <p className="fw-bold text-center">Descontos em:</p>
                  <div className="item-container">
                    <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>Atendimento Hospitalar.</p>
                    </div>
                    {item.benefits.map((benefit, i) => (
                      <>
                        {i === item.benefits.length - 1 && (<p />)}
                        {i !== item.benefits.length - 1 && (
                        <div className="item-wrapper" key={benefit}>
                          <div className="item-marker" />
                          <p>{benefit}</p>
                        </div>
                        )}
                      </>
                    ))}
                    {/* <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>Consultas com os melhores especialistas da região tocantina.</p>
                    </div>
                    <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>Exames de imagem e laboratoriais.</p>
                    </div>
                    <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>Fisioterapia.</p>
                    </div>
                    <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>Odontologia.</p>
                    </div>
                    <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>Descontos em óticas, farmácias, academias e universidade.</p>
                    </div>
                    <div className="item-wrapper">
                      <div className="item-marker" />
                      <p>
                        Adicional de dependentes por R$
                        {item.pricePerDependent.toFixed(2)}
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-2">
                <button type="button" className="select-product-cta" onClick={() => selectProduct({ ...item, benefits: '' })}>
                  Selecionar
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
