import { DateTime } from 'luxon';

export default function isValidDate(dateCtx: string) {
  try {
    const date = DateTime.fromFormat(dateCtx, 'dd-MM-yyyy');
    if (date.invalidReason !== null) {
      return false;
    }
    return true;
  } catch (err) {
    return false;
  }
}

export function isMajority(dateCtx: string) {
  try {
    const date = DateTime.fromFormat(dateCtx, 'dd-MM-yyyy');
    if (date.invalidReason !== null) {
      return false;
    }
    if (date.diffNow('years').years * -1 > 18) {
      return false;
    }
    return true;
  } catch (err) {
    return false;
  }
}
