/* eslint-disable max-len */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton, { IconType } from '../../components/IconButton';

export default function Partners() {
  const navigate = useNavigate();
  return (
    <div className="container-fluid partners-container" id="beneficios">
      <div className="row">
        <div className="col-12 text-center d-flex align-items-center justify-content-center flex-column">
          <h1 className="title-before-center title-internal text-center">Preços acessíveis, descontos reais e muitas vantagens.</h1>
          <h4>
            Contamos com diversos parceiros em vários setores, incluindo hospital, clínicas,
            farmácias, óticas, academias e universidade.
          </h4>
          <div className="btn-full">
            <IconButton
              onClick={() => navigate('/beneficios')}
              label="Veja a lista completa de nossa rede credenciada"
              icon={(
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14 0H0V14H14V0Z" fill="white" fillOpacity="0.01" />
                  <path d="M10.7917 5.54175V10.7917H5.54175" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M3.36694 3.36694L10.7916 10.7916" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

            )}
              type={IconType.PRIMARY}
            />
          </div>
          <div className="d-none btn-compacted">
            <IconButton
              onClick={() => navigate('/beneficios')}
              label="Veja a lista completa"
              icon={(
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14 0H0V14H14V0Z" fill="white" fillOpacity="0.01" />
                  <path d="M10.7917 5.54175V10.7917H5.54175" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M3.36694 3.36694L10.7916 10.7916" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

            )}
              type={IconType.PRIMARY}
            />
          </div>

        </div>
      </div>
    </div>
  );
}
