import React from 'react';

type BoletoOkProps = {
    link: string,
    email: string
}

export default function BoletoOk({ link, email }: BoletoOkProps) {
  return (
    <div className="d-flex flex-column align-items-center-justify-content-center">
      <span className="material-icons-round text-success text-center mb-2" style={{ fontSize: 42 }}>check_circle</span>
      <h6 className="text-center">Carnê gerado com sucesso!</h6>
      <p className="text-center">
        Segue abaixo o link para impressão.
        {' '}
      </p>
      <button type="button" onClick={() => window.open(link, '_blank')} className="btn-print mb-4">Imprimir boleto</button>
      <p>
        Após identificarmos o primeiro pagamento, enviaremos as instruções para o email
        {' '}
        <span className="fw-bold">{email}</span>
      </p>
    </div>
  );
}
