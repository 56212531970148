const emitents = [
  'SSP/AC',
  'SSP/AL',
  'SSP/AP',
  'SSP/AM',
  'SSP/BA',
  'SSP/CE',
  'SSP/DF',
  'SSP/ES',
  'SSP/GO',
  'SSP/MA',
  'SSP/MT',
  'SSP/MS',
  'SSP/MG',
  'SSP/PA',
  'SSP/PB',
  'SSP/PR',
  'SSP/PE',
  'SSP/PI',
  'SSP/RJ',
  'SSP/RN',
  'SSP/RS',
  'SSP/RO',
  'SSP/RR',
  'SSP/SC',
  'SSP/SP',
  'SSP/SE',
  'SSP/TO',
  'CARTORIO',
  'PF',
  'DETRAN',
  'ABNC',
  'CGPI/DUREX/DPF',
  'CGPI',
  'CGPMAF',
  'CNIG',
  'CNT',
  'COREN',
  'CORECON',
  'CRA',
  'CRAS',
  'CRB',
  'CRC',
  'CRE',
  'CREA',
  'CRECI',
  'CREFIT',
  'CRESS',
  'CRF',
  'CRM',
  'CRN',
  'CRO',
  'CRP',
  'CRPRE',
  'CRQ',
  'CRRC',
  'CRMV',
  'CSC',
  'CTPS',
  'DIC',
  'DIREX',
  'DPMAF',
  'DPT',
  'DST',
  'FGTS',
  'FIPE',
  'FLS',
  'GOVGO',
  'ICLA',
  'IFP',
  'IGP',
  'IICCECF/RO',
  'IIMG',
  'IML',
  'IPC',
  'IPF',
  'MAE',
  'MEX',
  'MMA',
  'OAB',
  'OMB',
  'PCMG',
  'PMMG',
  'POF/DPF',
  'POM',
  'SDS',
  'SNJ',
  'SECC',
  'SEJUSP',
  'SES/EST',
  'SESP',
  'SJS',
  'SJTC',
  'SJTS',
  'SPTC',
];

export default emitents;
