/* eslint-disable max-len */
import React from 'react';
import showToday from '../../helpers/dates';

type ModalTermsProps = {
    id: string
}

export default function ModalTerms({ id }: ModalTermsProps) {
  return (
    <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Termos de Uso
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body" style={{ textAlign: 'justify' }}>
            <h6 className="text-center fw-bold text-uppercase">Termos de Uso</h6>
            <p className="mt-4 mb-4">
              O Titular, anteriormente qualificado, será denominado, doravante, como CONTRATANTE.
            </p>
            <p className="mt-4 mb-4">
              CONTRATADA: REDESANTA SOLUCOES EM SAUDE LTDA., pessoa jurídica de direito privado, inscrita sob o CNPJ Nº 45.353.062/0001-15, com sede situada à Rua Paraíba Nº 749, Bairro Centro, Imperatriz – MA, telefone para contato: (99) 98454-4841, doravante denominada CONTRATADA.
            </p>
            <p className="mt-4 mb-4">
              <p className="fw-bold">DO OBJETO.</p>
              Cláusula 1ª: O serviço fornecido pela CONTRATADA não se enquadra na modalidade de plano de saúde. Logo, não assegura e não se responsabiliza pelos serviços fornecidos e pelo pagamento das despesas, tampouco garante desconto em todos os serviços necessariamente cobertos por plano de saúde.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 2ª: O serviço fornecido pela CONTRATADA consiste em convênio com estabelecimentos de saúde visando a obtenção de descontos pré-fixados.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 3ª: Os nomes e endereços dos profissionais e empresas que integram a REDESANTA, suas especialidades, os serviços por eles prestados, encontram-se à disposição destes no site www.redesanta.com, no aplicativo para smartphone e/ou na secretaria da sede administrativa da REDESANTA.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 4ª: O CONTRATANTE pode indicar até três dependentes para usufruir de todos os benefícios ofertados pela REDESANTA.
            </p>
            <p className="mt-4 mb-4">
              <p className="fw-bold">DO PAGAMENTO</p>
              Cláusula 5ª: pelos serviços prestados pela CONTRATADA ao A(O) CONTRATANTE, será fixada a remuneração no valor de R$ XX,XX, conforme descrição abaixo.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 6ª – É de inteira responsabilidade do(a) CONTRATANTE manter a CONTRATADA atualizada sobre quaisquer mudanças no registro e no método de pagamento, sob pena de interrupção parcial ou integral do serviço.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 7ª – A CONTRATADA não assume responsabilidade pelas informações fornecidas pelo(a) CONTRATANTE durante a assinatura do contrato, reservando-se o direito de regresso em caso de fraude.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 8ª – O(A) CONTRATANTE declara, expressamente, que tem pleno conhecimento que a adesão realizada ao Cartão de Benefícios da REDESANTA não é um plano de saúde e/ou um seguro de saúde, tratando-se de um cartão de descontos em serviços de saúde, não garantindo, em nenhuma hipótese, assistência privada e integral à saúde destes, na forma disposta na Lei 9656/1998.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 9ª - O presente contrato tem validade de 12 (doze) meses, não podendo ser rescindido antes deste prazo.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 10 - A presente contratação será renovada automaticamente após o término da vigência inicial, por mais 12 (doze) meses, sucessivamente, salvo se houver manifestação em contrário, com aviso prévio de 30 (trinta) dias, por qualquer uma das partes.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 11 - A utilização do cartão de descontos para fins de internação (clínica ou cirúrgica), consultas, exames e tratamentos médicos, odontológicos, exames laboratoriais e quaisquer outros serviços conveniados à CONTRATADA não está sujeita a nenhum tipo de carência, limite de utilização e não existe nenhuma restrição a qualquer doença congênita ou pré-existente.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 12 – A CONTRATADA declara não possuir rede própria para atendimento ao CONTRATANTE e/ou seus dependentes. Os procedimentos pactuados por meio deste instrumento serão realizados nas dependências dos prestadores de serviço, acompanhando a logística e disponibilidade desses, não havendo qualquer tipo de participação da CONTRATADA, salvo para garantir o objeto do contrato.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 13 – A CONTRATADA não se responsabiliza, em qualquer hipótese ou espécie civil, por qualquer relação comercial pactuada entre o(a) CONTRATANTE e um prestador de serviços para realização de procedimento não coberto pelo objeto do contrato.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 14 - Fica reservado o direito da CONTRATADA de, a seu critério e qualquer momento, ampliar ou reduzir a rede credenciada de serviços, visando adequá-la às demandas e necessidades de seus clientes.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 15: Anualmente, o valor da mensalidade será atualizado utilizando-se como base o IPCA (Índice Nacional de Preços ao Consumidor Amplo) dos últimos 12 (doze) meses, de forma automática.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 16 – O presente Contrato substitui todos os acordos e entendimentos havidos anteriormente entre as partes.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 17 – O presente instrumento somente poderá ser alterado mediante aditivo contratual, formalizado por escrito e assinado por ambas as partes.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 18 – A tolerância de uma parte para com a outra, relativamente ao descumprimento de qualquer das obrigações aqui assumidas, não será considerada novação, moratória ou renúncia a qualquer direito, constituindo mera liberalidade que não impedirá a parte tolerante, de exigir da outra o fiel e cabal cumprimento deste Contrato.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 19 – Nenhuma disposição deste Contrato deve ser interpretada de forma a proporcionar, direta ou indiretamente, a concessão de qualquer direito, recurso ou reclamação, sob qualquer pretexto, a terceiros.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 20 – Acordam as PARTES que se qualquer disposição deste Contrato for considerada ilegal, inexigível, nula ou inválida, as demais permanecerão em plena vigência.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 21 – As PARTES declaram, para todos os fins de direito, que não se verifica na presente contratação qualquer fato ou obrigação que possa vir a ser caracterizada como lesão civil, uma vez que reconhecem a proporcionalidade das prestações a serem assumidas em função deste Contrato.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 22 – As PARTES reconhecem que o presente Contrato tem natureza vinculativa, celebrando-o em caráter irrevogável e irretratável, obrigando-se ao seu fiel, pontual e integral cumprimento por si e por seus sucessores a qualquer título.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 23 - O presente Contrato vale como título executivo extrajudicial, na forma do artigo 784 do Código de Processo Civil Brasileiro, e o cumprimento de quaisquer das obrigações aqui constantes poderá vir a ser exigida na forma específica pela CONTRATADA, nos termos do disposto nos artigos 139, 536, 537, 815 e outros do Código de Processo Civil Brasileiro.
            </p>
            <p className="mt-4 mb-4">
              Cláusula 24 - As partes elegem o foro da comarca de Imperatriz – MA para dirimir quaisquer conflitos derivados deste contrato, com expressa renúncia de qualquer outro.
            </p>
            <p className="fw-bold">
              Imperatriz,
              {' '}
              {`${showToday()}`}
              .
            </p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close">Fechar</button>
          </div>
        </div>
      </div>
    </div>
  );
}
