/* eslint-disable max-len */
import React from 'react';

type PixOkProps = {
    reference: string
    image: string,
    page: string,
    qrCode: string,
    email: string,
}

export default function PixSuccess({
  reference, image, page, qrCode, email,
}: PixOkProps) {
  return (
    <div className="d-flex flex-column align-items-center-justify-content-center">
      <span className="material-icons-round text-success text-center mb-2" style={{ fontSize: 42 }}>check_circle</span>
      <h6 className="text-center fw-bold text-primary">Pix gerado com sucesso!</h6>
      <p className="text-center">Esse pagamento irá expirar em 30 dias.</p>
      <div className="d-flex justify-content-center">
        <img src={image} alt="pix" style={{ maxWidth: 250 }} />
      </div>

      <div className="d-block">
        <div className="qr-code-pix">{qrCode}</div>
      </div>
      <p className="text-center mt-4 fw-bold">
        Enviaremos também um email para
        {' '}
        {email}
        {' '}
        com as instruções de pagamento.
      </p>
    </div>
  );
}
