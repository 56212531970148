import React from 'react';

type CardOkProps = {
    email: string
}

export default function CardOk({ email }: CardOkProps) {
  return (
    <div className="d-flex flex-column align-items-center-justify-content-center">
      <span className="material-icons-round text-success text-center mb-2" style={{ fontSize: 42 }}>check_circle</span>
      <h6 className="text-center">Pagamento em processamento!</h6>
      <p className="text-center">
        Após confirmação do pagamento, enviaremos as instruções para o email
        {' '}
        <span className="fw-bold">{email}</span>
      </p>
    </div>
  );
}
