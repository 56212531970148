import React from 'react';
import { useNavigate } from 'react-router-dom';

interface IClientPassword {
    checkCode: () => void;
    codeInput: string;
    setCodeInput: (arg0: string) => void;
    error: string;
}

export default function ClientPassword({
  checkCode, codeInput, setCodeInput, error,
}: IClientPassword) {
  const navigate = useNavigate();
  return (
    <>
      <h5>Precisamos de mais algumas informações.</h5>
      <div className="col-12  d-flex align-items-center justify-content-center mt-4">
        <p className="text-accent">Digite abaixo o código fornecido por sua empresa para completar seu cadastro.</p>
      </div>
      <div className="input-wrapper">
        <label htmlFor="secret-input">
          <p>Código da empresa</p>
          <input id="secret-input" type="password" value={codeInput} onChange={(e) => setCodeInput(e.target.value)} />
        </label>
        <button type="button" onClick={() => checkCode()}>Próximo</button>
      </div>
      {error !== '' && (<p className="mt-4 situation-text-error text-center">{error}</p>)}
    </>
  );
}
