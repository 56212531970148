import React from 'react';

export default function LoadingChildren() {
  return (
    <div className="d-flex align-items-center justify-content-center flex-column">
      <div className="spinner-grow text-primary" role="status" />
      <p className="processing-payment">Processando Pagamento. Não feche esta janela até a conclusão.</p>
    </div>
  );
}
