/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';

interface IDefaultInputProps {
    id: string
    label: string
    error?: string
    onChange: (arg0: string) => void
    loading: boolean
    placeholder?: string
    value: string,
    readOnly?: boolean
    type?: string
    focusIn?: () => void
    focusOut?: () => void
}

export default function DefaultInput({
  label, error, onChange, loading, id, placeholder, value, readOnly, type, focusIn, focusOut,
}: IDefaultInputProps) {
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    document.getElementById(id)?.addEventListener('focusout', () => {
      if (typeof focusOut !== 'undefined') {
        focusOut();
      }
      if (error && error !== '') {
        setShowError(true);
      } else {
        setShowError(false);
      }
    });

    if (typeof focusIn !== 'undefined') {
      document.getElementById(id)?.addEventListener('focusin', () => {
        focusIn();
      });
    }
  }, [error]);
  return (
    <div className={`input-wrapper-2 ${error ? 'input-error' : ''} ${loading && 'input-loading'}`}>
      <label htmlFor={id} className="d-flex flex-column">
        {label}
        <input
          id={id}
          value={value}
          type={type || 'text'}
          readOnly={readOnly || false}
          onChange={(el) => onChange(el.target.value)}
          placeholder={placeholder || ''}
        />
      </label>
      <div className="spinner-container">
        <div className="spinner-grow text-light" role="status" />
      </div>
      {showError && error !== '' && (
      <span className="text-danger text-input-error d-flex align-items-center justify-content-center">
        <span className="material-icons-round">error</span>
        {error}
      </span>
      )}
    </div>
  );
}
