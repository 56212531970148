/* eslint-disable no-shadow */
/* eslint-disable max-len */
import React, { useState } from 'react';
import api from '../../config/axios';
import { unmaskData } from '../../helpers/maskInputs.js';

type LostPasswordProps = {
    cpf: string
}

enum ActualPage {
    LOADING,
    FORM,
    SUCCESS,
    ERROR
}

export default function LostPassword({ cpf }: LostPasswordProps) {
  const [actualPage, setActualPage] = useState(ActualPage.FORM);
  async function recoverPassword() {
    try {
      setActualPage(ActualPage.LOADING);
      await api.post('/user/recovery', {
        cpf: unmaskData(cpf),
      });
      setActualPage(ActualPage.SUCCESS);
    } catch (err) {
      setActualPage(ActualPage.ERROR);
    }
  }

  function handleContent() {
    if (actualPage === ActualPage.FORM) {
      return (
        <>
          <p>
            Deseja mesmo recuperar a senha para o CPF
            {' '}
            <strong>{cpf}</strong>
            ?
          </p>
          <p>
            Ao clicar em enviar iremos gerar uma nova senha e a encaminharemos ao seu email de cadastro.
          </p>

        </>
      );
    }
    if (actualPage === ActualPage.LOADING) {
      return (
        <p>Carregando, aguarde...</p>
      );
    }
    if (actualPage === ActualPage.ERROR) {
      return (
        <p>
          Ocorreu um erro ao recuperar senha. Verifique se o CPF:
          {' '}
          <strong>{cpf}</strong>
          {' '}
          está associado à uma conta.
        </p>
      );
    }
    return (
      <p>Enviamos as instrucões de recuperação para seu email cadastrado.</p>
    );
  }

  function handleButton() {
    if (actualPage === ActualPage.FORM) {
      return (
        <>
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
          <button type="button" className="btn btn-primary" onClick={() => recoverPassword()}>Enviar</button>
        </>
      );
    }
    if (actualPage === ActualPage.SUCCESS) {
      return <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setActualPage(ActualPage.FORM)}>Fechar</button>;
    }
    if (actualPage === ActualPage.LOADING) {
      return null;
    }
    return <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setActualPage(ActualPage.FORM)}>Fechar</button>;
  }

  return (
    <div className="modal fade" id="lostpw" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">Recuperar senha</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            {handleContent()}
          </div>
          <div className="modal-footer">
            {handleButton()}
          </div>
        </div>
      </div>
    </div>
  );
}
