/* eslint-disable react/prop-types */
import React, { createRef, useEffect, useState } from 'react';
import Pdf from 'react-to-pdf';

const ref = React.createRef();

export default function CardPDF({
  cardFrontSrc,
  cardBackSrc,
  registration,
  name,
  cpf,
  expiration,
  birth,
  modality,
  category,
}) {
  const [btnRef] = useState(createRef());
  return (
    <div className="modal fade" id="downloadCardModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Download do cartão</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            <Pdf
              targetRef={ref}
              filename={`${name}.pdf`}
              options={{
                unit: 'px',
                format: [522, 466],
                hotfixes: ['px_scaling'],
              }}
            >
              {({ toPdf }) => <button onClick={toPdf} type="button" className="d-none" ref={btnRef}>Generate Pdf</button>}
            </Pdf>
            <div ref={ref}>
              <div className="col-12 card-background">
                <img src={cardFrontSrc} alt="" />
              </div>
              <div className="col-12 card-background">
                <img src={cardBackSrc} alt="" />
                <div className="card-letters-wrapper">
                  <div className="card-letters-left">
                    <div>
                      <p>{registration}</p>
                    </div>
                    <div>
                      <p className="card-letters-title">Nome do beneficiário</p>
                      <p>{name}</p>
                    </div>
                    <div>
                      <p className="card-letters-title">cpf</p>
                      <p>{cpf}</p>
                    </div>
                    <div>
                      <p className="card-letters-title">Validade</p>
                      <p>{expiration}</p>
                    </div>
                  </div>
                  <div className="card-letters-right">
                    <div />
                    <div>
                      <p className="card-letters-title">Data Nasc.</p>
                      <p>{birth}</p>
                    </div>
                    <div>
                      <p className="card-letters-title">Modalidade</p>
                      <p>{modality}</p>
                    </div>
                    <div>
                      <p className="card-letters-title">Categoria</p>
                      <p>{category}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
            <button type="button" className="btn btn-primary" onClick={() => btnRef.current.click()}>Baixar</button>
          </div>
        </div>
      </div>
    </div>
  );
}
