import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ICpfPage {
    cpfInput: string;
    changeCpfInput: (arg0: string) => void;
    onAdvance: () => void;
    error: string;
}

export default function CpfPage({
  cpfInput, onAdvance, changeCpfInput, error,
}: ICpfPage) {
  const navigate = useNavigate();
  return (
    <>
      <h5>Área restrita à beneficiários</h5>
      <div className="input-wrapper">
        <label htmlFor="cpf-input">
          <p>CPF</p>
          <input id="cpf-input" value={cpfInput} onChange={(e) => changeCpfInput(e.target.value)} />
        </label>
        <button type="button" onClick={() => onAdvance()}>Avançar</button>
        {error !== '' && (<p className="mt-4 situation-text-error text-center">{error}</p>)}
      </div>
    </>
  );
}
