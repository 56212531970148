/* eslint-disable default-param-last */
/* eslint-disable no-shadow */
import { AnyAction } from 'redux';

type Logo = {
    fileUrl: string;
}

export type Partner = {
    id: number,
    email: string,
    name: string,
    services: string,
    cpfCnpj: string,
    isActive: boolean,
    logo: Logo,
}

const INITIAL_STORE: Partner = {
  id: 0,
  email: '',
  name: '',
  services: '',
  cpfCnpj: '',
  isActive: false,
  logo: {
    fileUrl: '',
  },
};

export enum PartnerDataEnum {
    SET,
    ERASE
}

export default function Store(state = INITIAL_STORE, action: AnyAction) {
  switch (action.type) {
    case PartnerDataEnum.SET:
      return { ...state, ...action.payload };
    default: return state;
  }
}
