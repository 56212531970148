import React from 'react';

type ErrorChildrenProps = {
    error: any
}

export default function ErrorChildren({ error }: ErrorChildrenProps) {
  if (error && error.error && error.error.error && error.error.error === 'Primeira cobrança negada pela operadora') {
    return (
      <div className="d-flex flex-column align-items-center-justify-content-center">
        <span className="material-icons-round text-danger text-center" style={{ fontSize: 42 }}>error</span>
        <p className="text-center">Primeira cobrança negada pela operadora!</p>
      </div>
    );
  }
  if (error && error.error && JSON.stringify(error.error).search('email')) {
    <div className="d-flex flex-column align-items-center-justify-content-center">
      <span className="material-icons-round text-danger text-center" style={{ fontSize: 42 }}>error</span>
      <p className="text-center">Erro ao processar pagamento. Email já está em uso em outro usuário</p>
    </div>;
  }
  if (error && error.error && error.error && error.error.messages && error.error.messages.errors.length > 0 && error.error.messages.errors[0].field === 'cpf') {
    return (
      <div className="d-flex flex-column align-items-center-justify-content-center">
        <span className="material-icons-round text-danger text-center" style={{ fontSize: 42 }}>error</span>
        <p className="text-center">Erro ao processar pagamento. cpf já está em uso em outro usuário</p>
      </div>
    );
  }
  if (error && error.error && error.error && error.error.messages && error.error.messages.errors.length > 0 && error.error.messages.errors[0].field === 'email') {
    return (
      <div className="d-flex flex-column align-items-center-justify-content-center">
        <span className="material-icons-round text-danger text-center" style={{ fontSize: 42 }}>error</span>
        <p className="text-center">Erro ao processar pagamento. email já está em uso em outro usuário</p>
      </div>
    );
  }
  return (
    <div className="d-flex flex-column align-items-center-justify-content-center">
      <span className="material-icons-round text-danger text-center" style={{ fontSize: 42 }}>error</span>
      <p className="text-center">Pagamento negado!</p>
    </div>
  );
}
