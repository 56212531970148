/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from 'react';
import Loading from '../../components/Loading';
import api from '../../config/axios';

interface WorkWithUsProps {
    show: boolean;
    setShowWorkWithUs: (arg0: boolean) => void;
}

enum WorkWithUsPageEnum {
  FORM,
  SUCCESS
}

export default function Lead({ show, setShowWorkWithUs }: WorkWithUsProps) {
  const [loading, setLoading] = useState(false);
  const [actualPage, setActualPage] = useState(WorkWithUsPageEnum.FORM);
  const [professional, setProfessional] = useState({
    name: '',
    email: '',
    company: '',
    phone: '',
  });

  async function sendWorkWithUs() {
    try {
      setLoading(true);
      await api.post('/website/partner-form', professional);
      setActualPage(WorkWithUsPageEnum.SUCCESS);
      setLoading(false);
    } catch (err) {
      setShowWorkWithUs(false);
    }
  }

  return (
    <div className={`work-with-us-container ${show ? '' : 'd-none'}`}>
      <div className="container-fluid">
        <div className="row d-flex">
          <div className="col-left" onClick={() => setShowWorkWithUs(false)} />

          { loading && (
            <div className="col-right d-flex flex-column justify-content-center align-items-center">
              <Loading />
            </div>
          )}

          { !loading && actualPage === WorkWithUsPageEnum.FORM && (
            <div className="col-right d-flex flex-column justify-content-around">
              <div className="close-wrapper">
                <button type="button" onClick={() => setShowWorkWithUs(false)}>X</button>
              </div>
              <div className="title-wrapper">
                <div className="title-wrapper">
                  <div className="title-container">
                    <h1 className="title-before">Farça parte!</h1>
                    <p>Seja um profissional / empresa credenciado à redesanta</p>
                  </div>
                </div>
              </div>
              <div className="input-wrapper">
                <label htmlFor="nome">
                  <p>Seu nome</p>
                  <input id="nome" onChange={(data) => setProfessional({ ...professional, name: data.target.value })} />
                </label>
                <label htmlFor="nome-profissão">
                  <p>Sua empresa / Profissão</p>
                  <input id="nomedaempresa" onChange={(data) => setProfessional({ ...professional, company: data.target.value })} />
                </label>
                <label htmlFor="nomedaempresa">
                  <p>Telefone</p>
                  <input id="nomedaempresa" onChange={(data) => setProfessional({ ...professional, phone: data.target.value })} />
                </label>
                <label htmlFor="nomedaempresa">
                  <p>Email</p>
                  <input id="nomedaempresa" onChange={(data) => setProfessional({ ...professional, email: data.target.value })} />
                </label>
                <button type="button" className="lead-submit" onClick={() => sendWorkWithUs()}>Quero ser parceiro</button>
              </div>
            </div>
          )}

          { !loading && actualPage !== WorkWithUsPageEnum.FORM && (
          <div className="col-right d-flex flex-column justify-content-center align-items-center">
            <div className="col-right d-flex flex-column justify-content-center align-items-center">
              <p>Formulário enviado com sucesso!</p>
              <button className="btn btn-primary mt-4" type="button" onClick={() => setShowWorkWithUs(false)}>Fechar</button>
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
}
