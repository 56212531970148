/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton, { IconType } from '../../components/IconButton';

interface HeaderCompactProps {
  setMenuVisibility: (arg0: boolean) => void;
  visibilityMenu: boolean;
}

export default function MenuArea({ visibilityMenu, setMenuVisibility }: HeaderCompactProps) {
  const navigate = useNavigate();
  return (
    <div className="mobile-menu-area">
      <div
        className="divisor-left"
        onClick={() => {
          if (visibilityMenu) {
            setMenuVisibility(!visibilityMenu);
          }
        }}
      />
      <div className="menu-area d-flex flex-column">
        <button type="button" className="menu-close-btn" onClick={() => setMenuVisibility(false)}>X</button>
        <IconButton
          onClick={() => window.location.assign('https://api.whatsapp.com/send?phone=5599985447392')}
          label="Fale com um representante"
          type={IconType.PRIMARY}
          icon={(
            <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="48" height="48" fill="white" fillOpacity="0.01" />
              <path fillRule="evenodd" clipRule="evenodd" d="M36.9974 21.7112C36.8434 13.0079 29.7401 6 21 6C12.1634 6 5 13.1634 5 22C5 26.1701 6.59531 29.9676 9.20892 32.8154L8.01043 40.0257L15.125 36.9699C18.2597 38.0122 21.218 38.2728 24 37.7516" fill="none" />
              <path d="M36.9974 21.7112C36.8434 13.0079 29.7401 6 21 6C12.1634 6 5 13.1634 5 22C5 26.1701 6.59531 29.9676 9.20892 32.8154L8.01043 40.0257L15.125 36.9699C18.2597 38.0122 21.218 38.2728 24 37.7516" stroke="#ffffff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M15.125 20.4667C16.3676 20.4667 17.375 19.4519 17.375 18.2C17.375 16.9482 16.3676 15.9333 15.125 15.9333C13.8824 15.9333 12.875 16.9482 12.875 18.2C12.875 19.4519 13.8824 20.4667 15.125 20.4667Z" fill="#ffffff" />
              <path d="M24.125 20.4667C25.3676 20.4667 26.375 19.4519 26.375 18.2C26.375 16.9482 25.3676 15.9333 24.125 15.9333C22.8824 15.9333 21.875 16.9482 21.875 18.2C21.875 19.4519 22.8824 20.4667 24.125 20.4667Z" fill="#ffffff" />
              <path fillRule="evenodd" clipRule="evenodd" d="M38.7618 39.9293C37.0135 41.2302 34.8467 42 32.5 42C26.701 42 22 37.299 22 31.5C22 25.701 26.701 21 32.5 21C38.299 21 43 25.701 43 31.5C43 33.0997 42.6423 34.6159 42.0024 35.9728" fill="none" />
              <path d="M38.7618 39.9293C37.0135 41.2302 34.8467 42 32.5 42C26.701 42 22 37.299 22 31.5C22 25.701 26.701 21 32.5 21C38.299 21 43 25.701 43 31.5C43 33.0997 42.6423 34.6159 42.0024 35.9728" stroke="#ffffff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
              <path fillRule="evenodd" clipRule="evenodd" d="M42.0024 35.9728L43 42L38.7618 39.9293" fill="none" />
              <path d="M42.0024 35.9728L43 42L38.7618 39.9293" stroke="#ffffff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M35.6875 30.7999C34.7555 30.7999 34 30.0388 34 29.0999C34 28.161 34.7555 27.3999 35.6875 27.3999C36.6195 27.3999 37.375 28.161 37.375 29.0999C37.375 30.0388 36.6195 30.7999 35.6875 30.7999Z" fill="#ffffff" />
              <path d="M28.9375 30.7999C28.0055 30.7999 27.25 30.0388 27.25 29.0999C27.25 28.161 28.0055 27.3999 28.9375 27.3999C29.8695 27.3999 30.625 28.161 30.625 29.0999C30.625 30.0388 29.8695 30.7999 28.9375 30.7999Z" fill="#ffffff" />
            </svg>
                )}
        />
        <ul>
          <li><a onClick={() => navigate('/login')}>Área do Cliente</a></li>
          {/* <li><a onClick={() => navigate('/beneficios')}>Ver toda rede credenciada</a></li> */}
          <li><a href="#sobre" onClick={() => setMenuVisibility(false)}>Quem somos</a></li>
          <li><a href="#produtos" onClick={() => setMenuVisibility(false)}>Soluções</a></li>
          <li><a href="#beneficios" onClick={() => setMenuVisibility(false)}>Benefícios</a></li>
          <li><a href="#profissionais" onClick={() => setMenuVisibility(false)}>Profissionais</a></li>
          <li><a href="#profissionais" onClick={() => setMenuVisibility(false)}>Profissionais</a></li>
          <li><a href="https://parceiro.redesanta.com" target="_blank" onClick={() => setMenuVisibility(false)} rel="noreferrer">Área do parceiro</a></li>
          <li><a href="https://play.google.com/store/apps/details?id=com.converta.redesanta" target="_blank" onClick={() => setMenuVisibility(false)} rel="noreferrer">App Android</a></li>
          <li><a href="https://apps.apple.com/br/app/redesanta/id6443803309?l=pt-br" target="_blank" onClick={() => setMenuVisibility(false)} rel="noreferrer">App iOS</a></li>
        </ul>
        <div className="menu-area-footer">
          <a href="https://www.instagram.com/redesantabr/" target="_blank" rel="noreferrer">
            <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="48" height="48" fill="white" fillOpacity="0.01" />
              <path d="M34 6H14C9.58172 6 6 9.58172 6 14V34C6 38.4183 9.58172 42 14 42H34C38.4183 42 42 38.4183 42 34V14C42 9.58172 38.4183 6 34 6Z" fill="none" stroke="#ffffff" strokeWidth="4" strokeLinejoin="round" />
              <path d="M24 32C28.4183 32 32 28.4183 32 24C32 19.5817 28.4183 16 24 16C19.5817 16 16 19.5817 16 24C16 28.4183 19.5817 32 24 32Z" fill="none" stroke="#ffffff" strokeWidth="4" strokeLinejoin="round" />
              <path d="M35 15C36.1046 15 37 14.1046 37 13C37 11.8954 36.1046 11 35 11C33.8954 11 33 11.8954 33 13C33 14.1046 33.8954 15 35 15Z" fill="#ffffff" />
            </svg>
          </a>
          <a href="mailto:redesanta@redesanta.com">
            <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M44 24V9H24H4V24V39H24" stroke="#ffffff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M44 34L30 34" stroke="#ffffff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M39 29L44 34L39 39" stroke="#ffffff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M4 9L24 24L44 9" stroke="#ffffff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
}
