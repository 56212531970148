/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import LostPassword from './LostPassword';

interface IPasswordPage {
    passwordLoginInput: string
    changePassordInput: (arg0: string) => void;
    error: string;
    returnToCPFPage: () => void;
    onAdvance: () => void;
    cpf: string
}

export default function PasswordPage({
  passwordLoginInput,
  changePassordInput,
  error,
  onAdvance,
  returnToCPFPage,
  cpf,
}: IPasswordPage) {
  return (
    <>
      <LostPassword cpf={cpf} />
      <h5>Área restrita à beneficiários</h5>
      <div className="input-wrapper">
        <label htmlFor="password-input">
          <p>Senha</p>
          <input id="password-input" value={passwordLoginInput} onChange={(e) => changePassordInput(e.target.value)} type="password" />
        </label>
        <a onClick={() => returnToCPFPage()}><p className="mt-4 text-center text-decoration-underline">Voltar à tela do CPF</p></a>
        <button type="button" onClick={() => onAdvance()}>Faz Login</button>
        <a onClick={() => null} data-bs-toggle="modal" data-bs-target="#lostpw"><p className="mt-4 text-center text-decoration-underline">Esqueceu sua senha?</p></a>
        {error !== '' && (<p className="mt-4 situation-text-error text-center">{error}</p>)}
      </div>
    </>
  );
}
