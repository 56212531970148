import { isValid as isValidCpf } from '@fnando/cpf';
import { isValid as isValidCnpj } from '@fnando/cnpj';

export function validateCpf(data: string) {
  return isValidCpf(data);
}

export function validateCnpj(data: string) {
  return isValidCnpj(data);
}

export function validateCnpjOrCpf(data: string) {
  if (validateCnpj(data)) {
    return true;
  } if (validateCpf(data)) {
    return true;
  }
  return false;
}
