/* eslint-disable max-len */
import React, { useState } from 'react';
import IconButton, { IconType } from '../../components/IconButton';

interface FAQProps {
  showWorkWithUs: (arg0: boolean) => void;
}

export default function FAQ({ showWorkWithUs }: FAQProps) {
  const [answer1, setAnswer1] = useState(false);
  const [answer2, setAnswer2] = useState(false);
  const [answer3, setAnswer3] = useState(false);
  const [answer4, setAnswer4] = useState(false);
  const [answer5, setAnswer5] = useState(false);
  const [answer6, setAnswer6] = useState(false);
  const [answer7, setAnswer7] = useState(false);
  return (
    <div className="container-fluid faq-container" id="faq">
      <div className="container">
        <h1 className="title">Perguntas Frequentes</h1>
        <div className="row">
          <div className="col-12 d-flex justify-content-center mt-4">
            <div className="question-wrapper">
              <div>
                <p className="question">Quem é redesanta ?</p>
                <button type="button" onClick={() => setAnswer1((prev) => !prev)}>{!answer1 ? '+' : '-'}</button>
              </div>
              <p className={!answer1 ? 'd-none' : ''}>A Redesanta é uma rede de referência e excelência na atenção à saúde. Constituída por um grupo multidisciplinar, garantimos acesso, praticidade, segurança, qualidade de entrega e conforto para nossos clientes. Oferecemos vantagens e benefícios em todos os setores da área da saúde.</p>
              <div className="divider" />
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center mt-4">
            <div className="question-wrapper">
              <div>
                <p className="question">Somos um plano de saúde ?</p>
                <button type="button" onClick={() => setAnswer2((prev) => !prev)}>{!answer2 ? '+' : '-'}</button>
              </div>
              <p className={!answer2 ? 'd-none' : ''}>
                <strong>Não somos um plano de saúde.</strong>
                Somos um programa de benefícios e vantagens, principalmente na área de saúde.
              </p>
              <div className="divider" />
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center mt-4">
            <div className="question-wrapper">
              <div>
                <p className="question">Quais benefícios oferecemos ?</p>
                <button type="button" onClick={() => setAnswer3((prev) => !prev)}>{!answer3 ? '+' : '-'}</button>
              </div>
              <p className={!answer3 ? 'd-none' : ''}>
                Inúmeros descontos e vantagens a depender do produto escolhido. No total oferecemos: Atendimento hospitalar,
                consultas com mais de 20 especialidades médicas, exames de imagem e laboratoriais, Fisioterapia, descontos em óticas,
                farmácias e academias, Odontologia, Universidade.
              </p>
              <div className="divider" />
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center mt-4">
            <div className="question-wrapper">
              <div>
                <p className="question">Quanto custa a assinatura ?</p>
                <button type="button" onClick={() => setAnswer4((prev) => !prev)}>{!answer4 ? '+' : '-'}</button>
              </div>
              <p className={!answer4 ? 'd-none' : ''}>
                Produto EMPRESARIAL: R$ 29,90 / Mês;
                {' '}
                <br />
                {' '}
                Produto INDIVIDUAL: R$ 39,90 / Mês;
                <br />
              </p>
              <div className="divider" />
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center mt-4">
            <div className="question-wrapper">
              <div>
                <p className="question">Qual a rede credenciada ?</p>
                <button type="button" onClick={() => setAnswer5((prev) => !prev)}>{!answer5 ? '+' : '-'}</button>
              </div>
              <p className={!answer5 ? 'd-none' : ''}>
                Contamos com o mais alto nível de profissionais.
                Entre nossos parceiros, podemos destacar: Hospital Santa Mônica, Clínica OTHOS, Clínica da Coluna, UROCLIN,
                e muito mais.
              </p>
              <div className="divider" />
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center mt-4">
            <div className="question-wrapper">
              <div>
                <p className="question">Quem pode ser adicionado como dependente ?</p>
                <button type="button" onClick={() => setAnswer7((prev) => !prev)}>{!answer7 ? '+' : '-'}</button>
              </div>
              <p className={!answer7 ? 'd-none' : ''}>Cônjuges e filhos até 18 anos.</p>
              <div className="divider" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
