import React from 'react';

type ChangeEmailProps = {
    setEmailInput: (arg0: string) => void;
    emailInput: string;
    sendNewEmail: () => void;
    error: string;
    returnToInfo: () => void;
}

export default function ChangeEmail({
  setEmailInput,
  emailInput,
  sendNewEmail,
  error,
  returnToInfo,
}: ChangeEmailProps) {
  return (
    <div className="col-12 mt-4 d-flex justify-content-center">
      <div className="input-wrapper row">
        <div className="col-12">
          <label htmlFor="cpf-input">
            <p>Novo email</p>
            <input
              id="name-input"
              type="email"
              onChange={(e) => setEmailInput(e.target.value)}
              value={emailInput}
            />
            {error !== '' && (<p className="text-danger mt-4 mb-4">{error}</p>)}
            <button type="button" onClick={() => sendNewEmail()}>Enviar Alteração</button>
            <button className="button-secondary" type="button" onClick={() => returnToInfo()}>Voltar</button>
          </label>
        </div>
      </div>
    </div>
  );
}
