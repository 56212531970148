/* eslint-disable no-shadow */
/* eslint-disable default-param-last */
import { AnyAction } from 'redux';

const INITIAL_STORE = {
  isLoggedIn: !false,
};

export enum UserLoginActionType {
    LOGIN = 'SET_LOGIN',
    LOGOFF = 'SET_LOGOFF',
}

export default function Store(state = INITIAL_STORE, action: AnyAction) {
  switch (action.type) {
    case UserLoginActionType.LOGIN:
      return { ...state, isLoggedIn: true };
    case UserLoginActionType.LOGOFF:
      return { ...state, isLoggedIn: false };
    default: return state;
  }
}
