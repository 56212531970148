import React from 'react';

type ChangePasswordProps = {
    setPasswordInput: (arg0: string) => void;
    passwordInput: string;
    setRepeatPasswordInput: (arg0: string) => void;
    repeatPasswordInput: string;
    sendNewPassword: () => void;
    error: string;
    returnToInfo: () => void;
}

export default function ChangePassword({
  setPasswordInput,
  setRepeatPasswordInput,
  passwordInput,
  repeatPasswordInput,
  sendNewPassword,
  error,
  returnToInfo,
}: ChangePasswordProps) {
  return (
    <div className="col-12 mt-4 d-flex justify-content-center">
      <div className="input-wrapper row">
        <div className="col-12">
          <label htmlFor="cpf-input">
            <p>Nova Senha</p>
            <input
              id="new-password-input"
              type="password"
              onChange={(e) => setPasswordInput(e.target.value)}
              value={passwordInput}
            />
          </label>
          <label htmlFor="cpf-input">
            <p>Repita nova senha</p>
            <input
              id="repeat-new-password-input"
              type="password"
              onChange={(e) => setRepeatPasswordInput(e.target.value)}
              value={repeatPasswordInput}
            />
            {error !== '' && (<p className="text-danger mt-4 mb-4">{error}</p>)}
            <button type="button" onClick={() => sendNewPassword()}>Enviar Alteração</button>
            <button className="button-secondary" type="button" onClick={() => returnToInfo()}>Voltar</button>
          </label>
        </div>
      </div>
    </div>
  );
}
